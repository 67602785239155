// import CryptoJS from "crypto-js";
// @ts-ignore
import CryptoJS from "crypto-js";

const SECRET_KEY = "CollegePass_256"; // A consistent key for encryption

// Simple encryption function
export function encrypt(text: string) {
  // Encrypt the text using AES encryption
  const encrypted = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();

  // Convert the encrypted text to Base64 and then make it URL-safe
  return btoa(encrypted)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Simple decryption function
export function decrypt(encryptedText: string) {
  // Convert the URL-safe Base64 back to the original Base64 format
  const base64 = encryptedText.replace(/-/g, "+").replace(/_/g, "/");

  // Decrypt the AES-encrypted string
  const bytes = CryptoJS.AES.decrypt(atob(base64), SECRET_KEY);

  // Convert the decrypted bytes back to the original string (UTF-8)
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
}
