import React from "react";
import { Route, RouterProvider, Routes } from "react-router-dom";
// @ts-ignore
import { authProtectedRoutes, publicRoutes, router } from "./allRoutes.tsx";
import Layout from "Layout";
import NonAuthLayout from "Layout/NonLayout";
import AuthProtected from "./AuthProtected";

const RouteIndex = () => {
  // return (
  //   <React.Fragment>
  //     <Routes>
  //       {authProtectedRoutes.map((route: any, idx: number) => (
  //         <Route
  //           key={idx}
  //           path={route.path}
  //           element={
  //             <AuthProtected>
  //               <Layout>
  //                 <route.component />
  //               </Layout>
  //             </AuthProtected>
  //           }
  //         />
  //       ))}
  //       {publicRoutes.map((route: any, idx: number) => (
  //         <Route
  //           path={route.path}
  //           key={idx}
  //           element={
  //             <NonAuthLayout>
  //               <route.component />
  //             </NonAuthLayout>
  //           } />
  //       ))}
  //     </Routes>
  //   </React.Fragment>
  // );
  return <RouterProvider router={router} />;
};

export default RouteIndex;
