// utils/axiosSingleton.ts
import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from "axios";
import { getAuthToken } from "slices/authv2";
// import { getau } from "./cookies"; // Assuming you have a function to get token from cookies

class AxiosSingleton {
  private static instance: AxiosInstance;

  private constructor() {
    // Prevent direct instantiation outside of the class
  }

  // Method to get the single Axios instance
  public static getInstance(): AxiosInstance {
    if (!AxiosSingleton.instance) {
      // Create the Axios instance if it doesn't exist yet
      AxiosSingleton.instance = axios.create({
        // baseURL: "https://api.collegepass.org", // Replace with your API base URL
        baseURL: "https://api.collegepass.org", // Replace with your API base URL
      });

      // Add token to the request headers using an interceptor
      AxiosSingleton.instance.interceptors.request.use(
        (config) => {
          const token = getAuthToken();
          console.log("Token", token);
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
    return AxiosSingleton.instance;
  }

  // Wrapper methods for axios requests
  // public static async get<T>(url: string, config?: any): Promise<T> {
  //   const response: AxiosResponse<T> = await this.getInstance().get(
  //     url,
  //     config
  //   );
  //   return response.data; // Return just the data
  // }

  // public static async post<T>(
  //   url: string,
  //   data: any,
  //   config?: any
  // ): Promise<T> {
  //   const response: AxiosResponse<T> = await this.getInstance().post(
  //     url,
  //     data,
  //     config
  //   );
  //   return response.data; // Return just the data
  // }

  // public static async put<T>(url: string, data: any, config?: any): Promise<T> {
  //   const response: AxiosResponse<T> = await this.getInstance().put(
  //     url,
  //     data,
  //     config
  //   );
  //   return response.data; // Return just the data
  // }

  // public static async delete<T>(url: string, config?: any): Promise<T> {
  //   const response: AxiosResponse<T> = await this.getInstance().delete(
  //     url,
  //     config
  //   );
  //   return response.data; // Return just the data
  // }

  // Method to get a new CancelTokenSource
  public static createCancelToken(): CancelTokenSource {
    return axios.CancelToken.source();
  }

  // Method to check if a request was canceled (expose axios.isCancel)
  public static isCancel(value: any): boolean {
    return axios.isCancel(value);
  }
}

export default AxiosSingleton;
