import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import store2, { Store2Context } from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// Combine reducers into a single root reducer
// const combinedReducer = combineReducers({
//   main: rootReducer,
//   secondary: store2, // Assign store2's reducer to a key
// });
const store = configureStore({ reducer: rootReducer, devTools: true });
root.render(
  // <React.StrictMode>
  <Provider store={store2}>
    {/* @ts-ignore */}
    {/* <Store2Context.Provider value={store2}> */}
    {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
    <App />
    {/* </BrowserRouter> */}
    {/* </Store2Context.Provider> */}
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
