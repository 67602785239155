// dashboard
import { createBrowserRouter } from "react-router-dom";

import { lazy, Suspense } from "react";

import Layout from "Layout";

const Analytics = lazy(() => import("pages/Dashboards/Analytics"));
const Ecommerce = lazy(() => import("pages/Dashboards/Ecommerce"));
const MyProfile = lazy(() => import("pages/MyProfile"));
const Email = lazy(() => import("pages/Dashboards/Email"));
const HRDashboard = lazy(() => import("pages/Dashboards/HR"));
const SocialMediaDashboard = lazy(() => import("pages/Dashboards/SocialMedia"));

// Chat
const Chat = lazy(() => import("pages/Chat"));

// Email
const Mailbox = lazy(() => import("pages/Email"));

// Calendar
const DefaultCalendar = lazy(() => import("pages/Calendar/Default"));
const MonthGrid = lazy(() => import("pages/Calendar/MonthGrid"));
const MultiMonthStack = lazy(() => import("pages/Calendar/MultiMonthStack"));

// Ecommerce
const ListView = lazy(() => import("pages/Ecommerce/Products/ListView"));
const GridView = lazy(() => import("pages/Ecommerce/Products/GridView"));
const Overview = lazy(() => import("pages/Ecommerce/Products/Overview"));
const AddNew = lazy(() => import("pages/Ecommerce/Products/AddNew"));
const ShoppingCart = lazy(() => import("pages/Ecommerce/ShoppingCart"));
const Checkout = lazy(() => import("pages/Ecommerce/Checkout"));
const Orders = lazy(() => import("pages/Ecommerce/Orders"));
const OrderOverview = lazy(() => import("pages/Ecommerce/OrderOverview"));
const Sellers = lazy(() => import("pages/Ecommerce/Sellers"));

// HR Management
// import EmployeeList from "pages/HRManagement/EmployeeList";
// import Holidays from "pages/HRManagement/Holidays";
// import LeaveManageEmployee from "pages/HRManagement/Leaves Manage/LeaveManageEmployee";
// import AddLeaveEmployee from "pages/HRManagement/Leaves Manage/AddLeaveEmployee";
// import LeaveManageHR from "pages/HRManagement/Leaves Manage/LeaveManageHR";
// import AddLeaveHR from "pages/HRManagement/Leaves Manage/AddLeaveHR";
// import AttendanceHR from "pages/HRManagement/Attendance/AttendanceHR";
// import MainAttendance from "pages/HRManagement/Attendance/MainAttendance";
// import Departments from "pages/HRManagement/Departments";
// import Estimates from "pages/HRManagement/Sales/Estimates";
// import Payments from "pages/HRManagement/Sales/Payments";
// import Expenses from "pages/HRManagement/Sales/Expenses";
// import EmployeeSalary from "pages/HRManagement/Payroll/EmployeeSalary";
// import Payslip from "pages/HRManagement/Payroll/Payslip";
// import CreatePayslip from "pages/HRManagement/Payroll/CreatePayslip";

// Notes
const Notes = lazy(() => import("pages/Notes"));
const Olympiads = lazy(() => import("pages/Olympiads"));
const OnlineCourses = lazy(() => import("pages/OnlineCourses"));
const Competitions = lazy(() => import("pages/Competitions"));
const ReadingList = lazy(() => import("pages/ReadingList"));
const NgoList = lazy(() => import("pages/NgoList"));
const Internship = lazy(() => import("pages/Internship"));
const Hackathons = lazy(() => import("pages/Hackathons"));
const Downloads = lazy(() => import("pages/Downloads"));
const MeritScholarships = lazy(() => import("pages/MeritScholarships"));
const ResearchPrograms = lazy(() => import("pages/ResearchPrograms"));
const Professors = lazy(() => import("pages/Professors"));
const SummerSchool = lazy(() => import("pages/SummerSchool"));
const Connections = lazy(() => import("pages/Connections"));

// Social Media
const Friends = lazy(() => import("pages/SocialMedia/Friends"));
const Event = lazy(() => import("pages/SocialMedia/Event"));
const WatchVideo = lazy(() => import("pages/SocialMedia/WatchVideo"));
const Marketplace = lazy(() => import("pages/SocialMedia/Marketplace"));

// Users
const UserListView = lazy(() => import("pages/Users/ListView"));
const UserGridView = lazy(() => import("pages/Users/GridView"));

// UI elements
const UiAlert = lazy(() => import("pages/Components/UIElement/UiAlert"));
const UiAvatar = lazy(() => import("pages/Components/UIElement/UIAvatar"));
const UiButtons = lazy(() => import("pages/Components/UIElement/UiButtons"));
const UIDrawer = lazy(() => import("pages/Components/UIElement/Drawer"));
const UiModal = lazy(() => import("pages/Components/UIElement/Modal"));
const UiLabel = lazy(() => import("pages/Components/UIElement/UILabel"));
const UiCards = lazy(() => import("pages/Components/UIElement/UiCards"));
const UiCollapse = lazy(() => import("pages/Components/UIElement/UiCollapse"));
const UiCountdown = lazy(
  () => import("pages/Components/UIElement/UiCountdown")
);
const UiDropdown = lazy(() => import("pages/Components/UIElement/UiDropdown"));
const UiGallery = lazy(() => import("pages/Components/UIElement/UiGallery"));
const UiLists = lazy(() => import("pages/Components/UIElement/UiLists"));
const UiNotification = lazy(
  () => import("pages/Components/UIElement/UiNotification")
);
const UiSpinners = lazy(() => import("pages/Components/UIElement/UiSpinners"));
const UITimeline = lazy(() => import("pages/Components/UIElement/UiTimeline"));
const UiProgressBar = lazy(
  () => import("pages/Components/UIElement/UiProgressBar")
);
const UITooltip = lazy(() => import("pages/Components/UIElement/UiTooltip"));
const UiVideo = lazy(() => import("pages/Components/UIElement/UiVideo"));

// Plugins
const PSimpleBar = lazy(() => import("pages/Components/Plugins/Simplebar"));
const PLightBox = lazy(() => import("pages/Components/Plugins/Lightbox"));
const SwiperSlider = lazy(
  () => import("pages/Components/Plugins/SwiperSlider")
);
const ScrollHint = lazy(() => import("pages/Components/Plugins/ScrollHint"));
const VideoPlayer = lazy(() => import("pages/Components/Plugins/VideoPlayer"));

// Navbar
const NavBars = lazy(() => import("pages/Components/Navigation/Navbars"));
const NavTabs = lazy(() => import("pages/Components/Navigation/Tabs"));
const NavigationBreadcrumb = lazy(
  () => import("pages/Components/Navigation/NavigationBreadcrumb")
);
const Pagination = lazy(() => import("pages/Components/Navigation/Pagination"));

// Forms
const FormsBasic = lazy(() => import("pages/Components/Forms/Basic"));
const FormValidation = lazy(() => import("pages/Components/Forms/Validation"));
const InputMask = lazy(() => import("pages/Components/Forms/InputMask"));
const FormSelect = lazy(() => import("pages/Components/Forms/Select"));
const CheckboxRadio = lazy(
  () => import("pages/Components/Forms/CheckboxRadio")
);
const FormSwitches = lazy(() => import("pages/Components/Forms/Switches"));
const FormWizard = lazy(
  () => import("pages/Components/Forms/Wizard/FormWizard")
);
const FileUpload = lazy(() => import("pages/Components/Forms/FileUpload"));
const FormDatePicker = lazy(() => import("pages/Components/Forms/Datepicker"));
const FormTimePicker = lazy(() => import("pages/Components/Forms/Timepicker"));
const FormColorPicker = lazy(
  () => import("pages/Components/Forms/Colorpicker")
);
const FormMultiSelect = lazy(
  () => import("pages/Components/Forms/MultiSelect")
);
const FormInputSpin = lazy(() => import("pages/Components/Forms/InputSpin"));
const FormClipboard = lazy(() => import("pages/Components/Forms/Clipboard"));
const EditorClassic = lazy(
  () => import("pages/Components/Forms/Editor/EditorClassic")
);

// Tables
const BasicTable = lazy(() => import("pages/Components/Table/Basic"));
const ReactDataTable = lazy(() => import("pages/Components/Table/ReactTable"));

// Icons
const RemixIcon = lazy(() => import("pages/Components/Icons/Remix"));
const LucidIcon = lazy(() => import("pages/Components/Icons/Lucide"));

// Maps
const MapsGoogle = lazy(() => import("pages/Components/MapsGoogle"));
const MapsLeaflet = lazy(() => import("pages/Components/MapsLeaflet"));

// Charts
const AreaCharts = lazy(() => import("pages/ApexCharts/AreaCharts/index"));
const BarCharts = lazy(() => import("pages/ApexCharts/BarCharts"));
const BoxplotCharts = lazy(() => import("pages/ApexCharts/BoxplotCharts"));
const BubbleCharts = lazy(() => import("pages/ApexCharts/BubbleCharts"));
const CandlstickCharts = lazy(
  () => import("pages/ApexCharts/CandlstickCharts")
);
const ColumnCharts = lazy(() => import("pages/ApexCharts/ColumnCharts"));
const FunnelCharts = lazy(() => import("pages/ApexCharts/FunnelCharts"));
const HeatmapChart = lazy(() => import("pages/ApexCharts/HeatmapChart"));
const LineChart = lazy(() => import("pages/ApexCharts/LineCharts"));
const MixedChart = lazy(() => import("pages/ApexCharts/MixedCharts/Index"));
const PieChart = lazy(() => import("pages/ApexCharts/PieCharts/Index"));
const PolarAreaChart = lazy(
  () => import("pages/ApexCharts/PolarAreaCharts/Index")
);
const RadarChart = lazy(() => import("pages/ApexCharts/RadarCharts/Index"));
const RadialbarChart = lazy(
  () => import("pages/ApexCharts/RadialBarCharts/Index")
);
const RangeAreaChart = lazy(() => import("pages/ApexCharts/RangeAreaCharts"));
const Scatterchart = lazy(() => import("pages/ApexCharts/ScatterCharts/Index"));
const TimeLinechart = lazy(
  () => import("pages/ApexCharts/TimeLineCharts/Index")
);
const Treemapchart = lazy(() => import("pages/ApexCharts/TreemapCharts/Index"));

// Landing
const OnePage = lazy(() => import("pages/Components/Landing/Onepage"));
const Product = lazy(() => import("pages/Components/Landing/Product"));

// Auth - Login
const Basic = lazy(() => import("pages/AuthenticationInner/Login/Basic"));
const LoginCover = lazy(
  () => import("pages/AuthenticationInner/Login/LoginCover")
);
const LoginBoxed = lazy(
  () => import("pages/AuthenticationInner/Login/LoginBoxed")
);
const LoginModern = lazy(
  () => import("pages/AuthenticationInner/Login/LoginModern")
);

// Auth - Register
const BasicRegister = lazy(
  () => import("pages/AuthenticationInner/Register/Basic")
);
const RegisterCover = lazy(
  () => import("pages/AuthenticationInner/Register/RegisterCover")
);
const RegisterBoxed = lazy(
  () => import("pages/AuthenticationInner/Register/RegisterBoxed")
);
const RegisterModern = lazy(
  () => import("pages/AuthenticationInner/Register/RegisterModern")
);

// Email Verification
const BasicEmailVerify = lazy(
  () => import("pages/AuthenticationInner/VerifyEmail/Basic")
);
const EmailCover = lazy(
  () => import("pages/AuthenticationInner/VerifyEmail/EmailCover")
);
const EmailModern = lazy(
  () => import("pages/AuthenticationInner/VerifyEmail/EmailModern")
);

// Two-Step Verification
const BasicTwoSteps = lazy(
  () => import("pages/AuthenticationInner/TwoSteps/Basic")
);
const TwoStepsCover = lazy(
  () => import("pages/AuthenticationInner/TwoSteps/TwoStepsCover")
);
const TwoStepsBoxed = lazy(
  () => import("pages/AuthenticationInner/TwoSteps/TwoStepsBoxed")
);
const TwoStepsModern = lazy(
  () => import("pages/AuthenticationInner/TwoSteps/TwoStepsModern")
);

// Logout
const BasicLogout = lazy(
  () => import("pages/AuthenticationInner/Logout/Basic")
);
const LogoutCover = lazy(
  () => import("pages/AuthenticationInner/Logout/LogoutCover")
);
const LogoutBoxed = lazy(
  () => import("pages/AuthenticationInner/Logout/LogoutBoxed")
);
const LogoutModern = lazy(
  () => import("pages/AuthenticationInner/Logout/LogoutModern")
);

// Reset Password
const BasicResetPassword = lazy(
  () => import("pages/AuthenticationInner/ResetPassword/Basic")
);
const ResetPasswordCover = lazy(
  () => import("pages/AuthenticationInner/ResetPassword/ResetPasswordCover")
);
const ResetPasswordBoxed = lazy(
  () => import("pages/AuthenticationInner/ResetPassword/ResetPasswordBoxed")
);
const ResetPasswordModern = lazy(
  () => import("pages/AuthenticationInner/ResetPassword/ResetPasswordModern")
);

// Create Password
const BasicCreatePassword = lazy(
  () => import("pages/AuthenticationInner/CreatePassword/Basic")
);
const CreatePasswordModern = lazy(
  () => import("pages/AuthenticationInner/CreatePassword/CreatePasswordModern")
);
const CreatePasswordCover = lazy(
  () => import("pages/AuthenticationInner/CreatePassword/CreatePasswordCover")
);
const CreatePasswordBoxed = lazy(
  () => import("pages/AuthenticationInner/CreatePassword/CreatePasswordBoxed")
);

// Authentication
const Login = lazy(() => import("pages/Authentication"));
const StudentLogin = lazy(() => import("pages/Authentication/StudentLogin"));
const AdvisorLogin = lazy(() => import("pages/Authentication/AdvisorLogin"));
const Logout = lazy(() => import("pages/Authentication/LogOut"));
const Pages404 = lazy(() => import("pages/AuthenticationInner/Pages404"));
const Register = lazy(() => import("pages/Authentication/Register"));
const UserProfile = lazy(() => import("pages/Authentication/UserProfile"));

// Pages
const Account = lazy(() => import("pages/Pages/Account"));
const ComingSoon = lazy(() => import("pages/AuthenticationInner/ComingSoon"));
const Offline = lazy(() => import("pages/AuthenticationInner/Offline"));
const Maintenance = lazy(() => import("pages/AuthenticationInner/Maintenance"));
const Settings = lazy(() => import("pages/Pages/Settings"));
const Pricing = lazy(() => import("pages/Pages/Pricing"));
const Faqs = lazy(() => import("pages/Pages/Faqs"));
const ContactUs = lazy(() => import("pages/Pages/ContactUs"));

// Custom Pages
const Meetings = lazy(() => import("../pages/Meetings"));
const UserList = lazy(() => import("../pages/UserList"));
const USShortlisting = lazy(
  () => import("../pages/Shortlisting/USShortlisting")
);
const ShortlistedColleges = lazy(
  () => import("../pages/Shortlisting/ShortlistedColleges")
);
const WorkExperience = lazy(() => import("../pages/WorkExperience"));
const Projects = lazy(() => import("../pages/Projects"));
const UKShortlisting = lazy(
  () => import("../pages/Shortlisting/UKShortlisting")
);
const UKShortlistedColleges = lazy(
  () => import("../pages/Shortlisting/UKShortlistedColleges")
);
const CanadaShortlisting = lazy(
  () => import("../pages/Shortlisting/CanadaShortlisting")
);
const APACShortlisting = lazy(
  () => import("../pages/Shortlisting/APACShortlisting")
);
const EuropeShortlisting = lazy(
  () => import("../pages/Shortlisting/EuropeShortlisting")
);

// Academics
const Grades = lazy(() => import("../pages/Academics/Grades"));
const GradesGpa = lazy(() => import("../pages/Academics/GradesGpa"));

// Extracurriculars
const MyActivities = lazy(
  () => import("../pages/Extracurriculars/MyActivities")
);

// Summer Programs
const SummerPrograms = lazy(() => import("../pages/Summer/Programs"));
const Plan = lazy(() => import("../pages/Summer/Plan"));

// Misc Pages
const Faq = lazy(() => import("../pages/Faq"));
const Fafsa = lazy(() => import("../pages/Fafsa"));
const Test = lazy(() => import("../pages/Testing/Test"));
const AdvisorNotes = lazy(() => import("../pages/AdvisorNotes"));
const StudentInformation = lazy(() => import("../pages/StudentInformation"));
const ReusableComponent = lazy(() => import("../pages/Reusable"));
const SchoolSupport = lazy(() => import("../pages/SchoolSupport"));
const CollegeEssays = lazy(() => import("../pages/CollegeEssays"));
const RecommendedBooks = lazy(() => import("../pages/RecommendedBooks"));
const Tasks = lazy(() => import("../pages/Tasks"));
const Home = lazy(() => import("../pages/Home"));

const ManagerUserList = lazy(() => import("../pages/ManagerUserList"));

interface RouteObject {
  path: string;
  element: any;
  // <React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  { path: "/dashboard", element: <MyProfile /> },
  { path: "/meetings", element: <Meetings /> },
  // { path: "/user-list", element: <UserList /> },
  // { path: "/shortlisted-college-us", element: <ShortlistedColleges /> },
  { path: "/us-shortlisting/:studentId", element: <USShortlisting /> },
  { path: "/us-shortlisting", element: <USShortlisting /> },
  {
    path: "/shortlisted-colleges-us/:studentId",
    element: <ShortlistedColleges />,
  },
  { path: "/shortlisted-colleges-us", element: <ShortlistedColleges /> },
  { path: "/projects", element: <Projects /> },
  // { path: "/work-experience", element: <WorkExperience /> },
  { path: "/uk-shortlisting/:studentId", element: <UKShortlisting /> },
  { path: "/uk-shortlisting", element: <UKShortlisting /> },
  {
    path: "/shortlisted-colleges-uk/:studentId",
    element: <UKShortlistedColleges />,
  },
  { path: "/shortlisted-colleges-uk", element: <UKShortlistedColleges /> },
  // { path: "/", element: <UserList /> },
  { path: "/", element: <Home /> },
  {path: '/manager-users', element: <ManagerUserList />},

  { path: "/canada-shortlisting", element: <CanadaShortlisting /> },
  { path: "/apac-shortlisting", element: <APACShortlisting /> },
  { path: "/europe-shortlisting", element: <EuropeShortlisting /> },
  { path: "/summer-programs", element: <SummerPrograms /> },
  { path: "/fafsa", element: <Fafsa /> },
  { path: "/faq", element: <Faq /> },
  // { path: "/summer-plan", element: <Plan /> },
  // { path: "/my-activities", element: <MyActivities /> },
  { path: "/grades/:studentId", element: <Grades /> },
  { path: "/grades", element: <Grades /> },
  { path: "/grades-gpa/:studentId", element: <GradesGpa /> },
  { path: "/grades-gpa", element: <GradesGpa /> },
  { path: "/grades-gpa/:gradeId/:studentId", element: <GradesGpa /> },
  { path: "/grades-gpa/:gradeId", element: <GradesGpa /> },
  // { path: "/advisor-notes", element: <AdvisorNotes /> },
  // { path: "/testing", element: <Test /> },
  { path: "/student-information", element: <StudentInformation /> },
  { path: "/student-information/:studentId", element: <StudentInformation /> },
  { path: "/reusable-components", element: <ReusableComponent /> },
  // { path: "/recommended-books", element: <RecommendedBooks /> },
  // { path: "/task", element: <Tasks /> },
  { path: "/recommendation-letters", element: <SchoolSupport /> },
  { path: "/recommendation-letters/:studentId", element: <SchoolSupport /> },

  // Dashboard
  { path: "/apps-users-list", element: <UserList /> }, //{ path: "/dashboard", element: <MyProfile /> },
  { path: "/ecommerce", element: <Ecommerce /> },
  // { path: "/", element: <Ecommerce /> },
  // { path: "/", element: <UserList /> },
  // { path: "/dashboards-analytics", element: <Analytics /> },
  // { path: "/dashboards-email", element: <Email /> },
  // { path: "/dashboards-hr", element: <HRDashboard /> },
  // { path: "/dashboards-social", element: <SocialMediaDashboard /> },
  // { path: "/reusable-components", element: <ReusableComponent /> },
  { path: "/college-essays", element: <CollegeEssays /> },
  { path: "/college-essays/:studentId", element: <CollegeEssays /> },

  // Notes
  { path: "/apps-notes", element: <Notes /> },
  { path: "/olympiads", element: <Olympiads /> },
  { path: "/online-courses", element: <OnlineCourses /> },
  { path: "/competitions", element: <Competitions /> },
  { path: "/reading-list", element: <ReadingList /> },
  { path: "/ngo-list", element: <NgoList /> },
  { path: "/internships", element: <Internship /> },
  { path: "/hackathons", element: <Hackathons /> },
  { path: "/downloads", element: <Downloads /> },
  { path: "/merit-scholarships", element: <MeritScholarships /> },
  { path: "/merit-scholarships/:studentId", element: <MeritScholarships /> },
  { path: "/research-programs", element: <ResearchPrograms /> },
  { path: "/professors", element: <Professors /> },
  { path: "/summer-school", element: <SummerSchool /> },
  { path: "/connections", element: <Connections /> },

  // pages
  { path: "/pages-account", element: <Account /> },
  { path: "/pages-account-settings", element: <Settings /> },
  { path: "/pages-pricing", element: <Pricing /> },
  { path: "/pages-faqs", element: <Faqs /> },
  { path: "/pages-contact-us", element: <ContactUs /> },

  // profile
  { path: "/user-profile", element: <UserProfile /> },
  { path: "/:studentId", element: <MyProfile /> },
];

const publicRoutes = [
  // authentication
  { path: "/login", element: <Login /> },
  { path: "/student-login", element: <StudentLogin /> },
  { path: "/advisor-login", element: <AdvisorLogin /> },
  { path: "/logout", element: <Logout /> },
  { path: "/register", element: <Register /> },
];

const router = createBrowserRouter([
  {
    element: (
      <Suspense fallback={<div>Loading layout...</div>}>
        <Layout />
      </Suspense>
    ),
    children: authProtectedRoutes,
  },
  ...publicRoutes,
]);

export { router, authProtectedRoutes, publicRoutes };
