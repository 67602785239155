import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Trophy,
  UserRound,
  HeartIcon,
} from "lucide-react";

const menuData: any = [
  {
    id: "home",
    label: "Home",
    link: "/",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-house"
      >
        <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
        <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      </svg>
    ),
  },
  // {
  //   id: "landing",
  //   label: "Meetings",
  //   link: "/meetings",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-presentation"
  //     >
  //       <path d="M2 3h20" />
  //       <path d="M21 3v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3" />
  //       <path d="m7 21 5-5 5 5" />
  //     </svg>
  //   ),
  //   // subItems: [
  //   //     {
  //   //         id: 'onepage',
  //   //         label: 'One Page',
  //   //         link: '/onepage-landing',
  //   //         parentId: "landing"
  //   //     },
  //   //     {
  //   //         id: 'productlanding',
  //   //         label: 'Product',
  //   //         link: '/product-landing',
  //   //         parentId: "landing"
  //   //     }
  //   // ]
  // },
  // {
  //     label: 'Apps',
  //     isTitle: true,
  // },
  // {
  //   id: "chat",
  //   label: "Tasks",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-picture-in-picture"
  //     >
  //       <path d="M8 4.5v5H3m-1-6 6 6m13 0v-3c0-1.16-.84-2-2-2h-7m-9 9v2c0 1.05.95 2 2 2h3" />
  //       <rect width="10" height="7" x="12" y="13.5" ry="2" />
  //     </svg>
  //   ),
  //   link: "/task",
  //   parentId: 2,
  // },
  // {
  //   id: "email",
  //   label: "Email",
  //   icon: <Mail />,
  //   link: "/apps-mailbox",
  //   parentId: 2,
  // },
  // {
  //   id: "email",
  //   label: "Advisor Notes",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-notebook-pen"
  //     >
  //       <path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4" />
  //       <path d="M2 6h4" />
  //       <path d="M2 10h4" />
  //       <path d="M2 14h4" />
  //       <path d="M2 18h4" />
  //       <path d="M21.378 5.626a1 1 0 1 0-3.004-3.004l-5.01 5.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
  //     </svg>
  //   ),
  //   link: "/advisor-notes",
  //   parentId: 2,
  // },
  {
    id: "profile",
    label: "My Profile",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-circle-user"
      >
        <circle cx="12" cy="12" r="10" />
        <circle cx="12" cy="10" r="3" />
        <path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662" />
      </svg>
    ),
    link: "/",
    parentId: 2,
  },
  {
    id: "studentInfo",
    label: "Student Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-square-library"
      >
        <rect width="18" height="18" x="3" y="3" rx="2" />
        <path d="M7 7v10" />
        <path d="M11 7v10" />
        <path d="m15 7 2 10" />
      </svg>
    ),
    link: "/student-information",
    parentId: 2,
  },
  {
    id: "shortlisting",
    label: "College Shortlisting",
    link: "/#",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-notepad-text"
      >
        <path d="M8 2v4" />
        <path d="M12 2v4" />
        <path d="M16 2v4" />
        <rect width="16" height="18" x="4" y="4" rx="2" />
        <path d="M8 10h6" />
        <path d="M8 14h8" />
        <path d="M8 18h5" />
      </svg>
    ),
    subItems: [
      {
        id: "us",
        label: "US",
        link: "/us-shortlisting",
        parentId: "shortlisting",
      },
      {
        id: "uk",
        label: "UK",
        link: "/uk-shortlisting",
        parentId: "shortlisting",
      },
      // {
      //   id: "multimonth",
      //   label: "Canada",
      //   link: "/canada-shortlisting",
      //   parentId: "calendar",
      // },
      // {
      //   id: "multimonth",
      //   label: "APAC",
      //   link: "/apac-shortlisting",
      //   parentId: "calendar",
      // },
      // {
      //   id: "multimonth",
      //   label: "Europe",
      //   link: "/europe-shortlisting",
      //   parentId: "calendar",
      // },
    ],
  },
  {
    id: "academics",
    label: "Academics",
    link: "/#",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-school"
      >
        <path d="M14 22v-4a2 2 0 1 0-4 0v4" />
        <path d="m18 10 4 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8l4-2" />
        <path d="M18 5v17" />
        <path d="m4 6 8-4 8 4" />
        <path d="M6 5v17" />
        <circle cx="12" cy="9" r="2" />
      </svg>
    ),
    subItems: [
      {
        id: "grades",
        label: "Grades",
        link: "/grades",
        parentId: "academics",
      },
      // {
      //   id: "checkout",
      //   label: "Testing",
      //   link: "/testing",
      //   parentId: "ecommerce",
      // },
      // {
      //   id: "order",
      //   label: "Olympiads",
      //   link: "/olympiads",
      //   parentId: "ecommerce",
      // },
      // {
      //   id: "orderoverview",
      //   label: "Online Courses",
      //   link: "/online-courses",
      //   parentId: "ecommerce",
      // },
      // {
      //   id: "sellers",
      //   label: "Reading List",
      //   link: "/reading-list",
      //   parentId: "ecommerce",
      // },
    ],
  },
  // {
  //   id: "hr-management",
  //   label: "Summer",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-sun"
  //     >
  //       <circle cx="12" cy="12" r="4" />
  //       <path d="M12 2v2" />
  //       <path d="M12 20v2" />
  //       <path d="m4.93 4.93 1.41 1.41" />
  //       <path d="m17.66 17.66 1.41 1.41" />
  //       <path d="M2 12h2" />
  //       <path d="M20 12h2" />
  //       <path d="m6.34 17.66-1.41 1.41" />
  //       <path d="m19.07 4.93-1.41 1.41" />
  //     </svg>
  //   ),
  //   parentId: "hrmanagement",
  //   link: "/#",
  //   subItems: [
  //     {
  //       id: "employeelist",
  //       label: "Programs",
  //       link: "/summer-programs",
  //       parentId: "hrmanagement",
  //     },
  //     {
  //       id: "holiday",
  //       label: "Plan",
  //       link: "/summer-plan",
  //       parentId: "hrmanagement",
  //     },
  //   ],
  // },

  // {
  //   id: "social",
  //   label: "Extracurriculars",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-picture-in-picture"
  //     >
  //       <path d="M8 4.5v5H3m-1-6 6 6m13 0v-3c0-1.16-.84-2-2-2h-7m-9 9v2c0 1.05.95 2 2 2h3" />
  //       <rect width="10" height="7" x="12" y="13.5" ry="2" />
  //     </svg>
  //   ),
  //   subItems: [
  //     {
  //       id: "friends",
  //       label: "My Activities",
  //       link: "/my-activities",
  //       parentId: "social",
  //     },
  //     {
  //       id: "event",
  //       label: "NGO List",
  //       link: "/ngo-list",
  //       parentId: "social",
  //     },
  //     {
  //       id: "watchvideo",
  //       label: "Internships",
  //       link: "/internships",
  //       parentId: "social",
  //     },
  //     {
  //       id: "marketplace",
  //       label: "Hackathons",
  //       link: "/hackathons",
  //       parentId: "social",
  //     },
  //     {
  //       id: "marketplace",
  //       label: "Recommended Books",
  //       link: "/recommended-books",
  //       parentId: "social",
  //     },
  //   ],
  // },
  {
    id: "recommendationLetters",
    label: "Recommendation Letters",
    icon: <ScrollText />,
    link: "/recommendation-letters",
    parentId: 2,
  },
  // {
  //   id: "notes",
  //   label: "Connections",
  //   icon: <RadioTower />,
  //   link: "/connections",
  //   parentId: 2,
  // },
  {
    id: "essay",
    label: "Essay",
    icon: <FileText />,
    parentId: 2,
    subItems: [
      // {
      //   id: "invoicelistview",
      //   label: "Summer School",
      //   link: "/school-support",
      //   parentId: "invoice",
      // },
      {
        id: "collegeEssay",
        label: "Colleges",
        link: "/college-essays",
        parentId: "essay",
      },
      // {
      //     id: 'invoiceoverview',
      //     label: 'Overview',
      //     link: '/apps-invoice-overview',
      //     parentId: 'invoice'
      // }
    ],
  },
  // {
  //   id: "users",
  //   label: "Research",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-folder-search-2"
  //     >
  //       <circle cx="11.5" cy="12.5" r="2.5" />
  //       <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2Z" />
  //       <path d="M13.3 14.3 15 16" />
  //     </svg>
  //   ),
  //   parentId: 2,
  //   subItems: [
  //     {
  //       id: "userlistview",
  //       label: "Research Focus",
  //       link: "/#",
  //       parentId: "users",
  //     },
  //     {
  //       id: "userlistview",
  //       label: "Research Programs",
  //       link: "/research-programs",
  //       parentId: "users",
  //     },
  //     {
  //       id: "usergridview",
  //       label: "Professors List",
  //       link: "/professors",
  //       parentId: "users",
  //     },
  //   ],
  // },
  {
    id: "financialAid",
    label: "Financial Aid",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-coins"
      >
        <circle cx="8" cy="8" r="6" />
        <path d="M18.09 10.37A6 6 0 1 1 10.34 18" />
        <path d="M7 6h1v4" />
        <path d="m16.71 13.88.7.71-2.82 2.82" />
      </svg>
    ),
    parentId: 2,
    subItems: [
      {
        id: "scholarship",
        label: " Scholarships",
        link: "/merit-scholarships",
        parentId: "financialAid",
      },
      // {
      //   id: "userlistview",
      //   label: "FAFSA",
      //   link: "/fafsa",
      //   parentId: "users",
      // },
    ],
  },
  {
    id: "notes",
    label: "FAQs",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-message-circle-question"
      >
        <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
        <path d="M12 17h.01" />
      </svg>
    ),
    link: "/faq",
    parentId: 2,
  },
  // {
  //   id: "users",
  //   label: "Misc. Resources",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       stroke-width="2"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //       className="lucide lucide-circle-gauge"
  //     >
  //       <path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
  //       <circle cx="12" cy="12" r="2" />
  //       <path d="M13.4 10.6 19 5" />
  //     </svg>
  //   ),
  //   parentId: 2,
  //   subItems: [
  //     {
  //       id: "userlistview",
  //       label: "Downloads",
  //       link: "/downloads",
  //       parentId: "users",
  //     },
  //   ],
  // },
];

const counsellorData: any = [
  {
    id: "dashboard",
    label: "Home",
    link: "/#",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-house"
      >
        <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
        <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      </svg>
    ),
  },

  {
    id: "managerView",
    label: "Student List",
    link: "/manager-users",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-house"
      >
        <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
        <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      </svg>
    ),
  },
];

export { menuData, counsellorData };
