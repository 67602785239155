import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import studentsSlice, {
  loadStudentsStateFromLocalStorage,
  saveStudentsStateToLocalStorage,
} from "./slices/students";

import authSlice, {
  loadAuthStateFromLocalStorage,
  saveAuthStateToLocalStorage,
} from "./slices/authv2";
import layoutSlice from "./slices/layout";
import { createContext, useContext } from "react";

// Load the initial `students` state from localStorage
const persistedStudentsState = loadStudentsStateFromLocalStorage();
const persistedAuthState = loadAuthStateFromLocalStorage();

const store = configureStore({
  reducer: {
    students: studentsSlice,
    auth: authSlice,
    layout: layoutSlice,
  },
  devTools: true,
  preloadedState: {
    students: persistedStudentsState || {}, // Initialize with saved state or empty object
    auth: persistedAuthState || {},
  },
});

// Subscribe to Redux store changes to save the `students` state
let previousStudentsState = store.getState().students; // Initialize with current state
let previousAuthState = store.getState().auth; // Initialize with current state

store.subscribe(() => {
  const currentStudentsState = store.getState().students; // Get current students state
  const currentAuthState = store.getState().auth; // Get current students state

  // Log current and previous states for debugging
  console.log("Previous Students State:", previousStudentsState);
  console.log("Current Students State:", currentStudentsState);

  // // Check if the `students` state has changed
  // if (previousStudentsState !== currentStudentsState) {
  //   saveStudentsStateToLocalStorage(currentStudentsState); // Save only when students state changes
  //   previousStudentsState = currentStudentsState; // Update the reference to current state
  // }
  // console.log(previousAuthState);
  // // Check if the `auth` state has changed
  // if (previousAuthState !== currentAuthState) {
  //   saveAuthStateToLocalStorage(currentAuthState); // Save only when auth state changes
  //   previousAuthState = currentAuthState; // Update the reference to current state
  // }

  // Check if the `students` state has changed
  if (
    JSON.stringify(previousStudentsState) !==
    JSON.stringify(currentStudentsState)
  ) {
    saveStudentsStateToLocalStorage(currentStudentsState); // Save only when students state changes
    previousStudentsState = currentStudentsState; // Update the reference to current state
  }

  // Check if the `auth` state has changed
  if (JSON.stringify(previousAuthState) !== JSON.stringify(currentAuthState)) {
    saveAuthStateToLocalStorage(currentAuthState); // Save only when auth state changes
    previousAuthState = currentAuthState; // Update the reference to current state
  }
});

// * ReturnType is a type which excepts function type and returns return type of that function
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

// Create Context for store2
export const Store2Context = createContext(null);

// Store2 Provider Component
// export const Store2Provider = ({ children }: { children: any }) => {
//   return (
//     <Store2Context value={store2}>{children}</Store2Context.Provider>
//   );
// };

// Hook to use store2
export const useStore2 = () => useContext(Store2Context);
