import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

// Define the shape of your student data
export interface AuthState {
  // token?: string | null;
  isAuthenticated?: boolean | null;
  status?: "idle" | "loading" | "error"; // Individual loading state,
  email?: string;
  fullName?: string;
  userRole?: number | null;
  userId?: number | null;
  isParent?: number | null;
  gender?: string | null;
  error?: string | null;
  specialization?: string | null;
  citizenship?: string | number | null;
}

// Initial state (empty by default)
const initialState: AuthState = {
  // token: null,
  isAuthenticated: null,
  status: "idle",
  email: "",
  gender: "",
  fullName: "",
  userRole: null,
  userId: null,
  isParent: null,
  specialization: null,
};
export const saveAuthToken = (token: string) => {
  try {
    cookies.set("CollegePass_AuthToken", token, { path: "/" });
  } catch (err) {
    console.log("Error saving auth token", err);
  }
};

export const getAuthToken = (): string | null | undefined => {
  try {
    return cookies.get("CollegePass_AuthToken") as string | undefined | null;
  } catch (err) {
    console.log("Error getting auth token", err);
    // return null;
  }
};

export const login = createAsyncThunk(
  "auth/login",
  async function (
    cred: { email: string; password: string; type: "student" | "counsellor" },
    thunkAPI
  ) {
    try {
      // 1) We get the user's geolocation position
      const user: AuthState & { token: string } = await axios.post(
        `https://api.collegepass.org/masters/user/${
          cred.type === "student" ? "studentLogin" : "counsellorLogin"
        }`,
        {
          email: cred.email,
          password: cred.password,
        }
      );
      if (user.token) {
        cookies.set("CollegePass_AuthToken", user.token, { path: "/" });
      }
      window.open("/", "_self");
      return user;
    } catch (error: any) {
      console.log("Error", error);
      return thunkAPI.rejectWithValue({
        message: error?.response?.data?.message,
      });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reloadAuth(state: AuthState, action: PayloadAction<AuthState>) {
      state = { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading"; // Set status to loading
        state.error = null; // Clear any previous error
      })
      .addCase(login.fulfilled, (state, action) => {
        const user = action.payload as AuthState; // Payload will be the user data
        state.isAuthenticated = true; // Mark user as authenticated
        state.status = "idle"; // Set status back to idle
        state.email = user.email || ""; // Set user details from payload
        state.fullName = user.fullName || "";
        state.userRole = user.userRole;
        state.userId = user.userId;
        state.gender = user.gender;
        state.isParent = user.isParent;
        state.specialization = user.specialization;
        state.error = null; // Clear error
        state.citizenship = user.citizenship;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "error"; // Set status to error
        state.isAuthenticated = false; // Mark user as not authenticated
        state.error =
          (action.payload as { message: string })?.message || "Unknown error"; // Store error message
      });
  },
});

// Function to save the `students` state to localStorage
export const saveAuthStateToLocalStorage = (auth: AuthState | undefined) => {
  try {
    const serializedState = JSON.stringify(auth); // Serialize the students object
    localStorage.setItem("CollegePass_Auth", serializedState); // Save to localStorage
  } catch (error) {
    console.error("Could not save state to localStorage:", error);
  }
};

// Function to load the `students` state from localStorage
export const loadAuthStateFromLocalStorage = (): AuthState | undefined => {
  try {
    const serializedState = localStorage.getItem("CollegePass_Auth");
    if (serializedState === null) {
      return undefined; // No data in localStorage
    }
    return JSON.parse(serializedState); // Parse the saved state
  } catch (error) {
    console.error("Could not load state from localStorage:", error);
    return undefined;
  }
};

type DispatchFunction = () => AppDispatch;

export const useAuthDispatch: DispatchFunction = useDispatch;
export const useAuthSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getAuth = (state: RootState) => state.auth;

export const { reloadAuth } = authSlice.actions;

export default authSlice.reducer;
