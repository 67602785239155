// hooks/useStudentData.js
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuth, useAuthSelector } from "slices/authv2";
import {
  useStudentsSelector,
  selectStudentById,
  StudentInfo,
} from "slices/students";
import { decrypt } from "utils/hash";

const useStudentData = (): {
  studentData: StudentInfo | null;
  studentId: string | number | null | undefined;
  isLoadingStudent: boolean;
  encryptedStudentId: string | null | undefined;
} => {
  const { studentId } = useParams(); // Get studentid from URL params, if present
  const auth = useAuthSelector(getAuth);
  const [isLoadingStudent, setIsLoadingStudent] = useState(false);

  // If there's a studentid in the route, decrypt it for counselor's use
  const decryptedStudentId = studentId ? decrypt(studentId) : null;

  // If there's a decryptedStudentId, use it; otherwise fallback for students
  const studentData = useStudentsSelector(
    (state) =>
      decryptedStudentId
        ? selectStudentById(state, decryptedStudentId) // For counselors
        : selectStudentById(state, "defaultStudentId") // For students (without ID)
  );

  const [studentID, setStudentID] = useState<string | number | null>();

  useEffect(() => {
    setIsLoadingStudent(true);
    if (auth.userRole === 1 && auth.userId) {
      if (auth.isParent !== 1 && decryptedStudentId) {
        setStudentID(decryptedStudentId);
      } else {
        setStudentID(auth.userId);
      }
    } else if (decryptedStudentId) {
      setStudentID(decryptedStudentId);
    }
    setIsLoadingStudent(false);
  }, [auth.userId, auth.userRole, decryptedStudentId]);
  // console.log("Studentid", studentId);
  // console.log("Studentid", decryptedStudentId);
  // console.log("Studentid", studentID);

  return {
    encryptedStudentId: studentId || null,
    studentId: studentID,
    studentData: studentData,
    isLoadingStudent,
  };
};

export default useStudentData;
