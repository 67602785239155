import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
// Constants
import {
  LAYOUT_TYPES,
  LAYOUT_SEMI_DARK,
  LAYOUT_SKIN,
  LAYOUT_MODE_TYPES,
  LAYOUT_DIRECTION,
  LAYOUT_CONTENT_WIDTH,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_NAVIGATION_TYPES,
  LEFT_SIDEBAR_COLOR_TYPES,
  LAYOUT_TOPBAR_THEME_TYPES,
} from "Common/constants/layout";
import { changeHTMLAttribute } from "../layouts/utils";
import { AppDispatch, RootState } from "store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// Layout State Interface
export interface LayoutState {
  layoutType: LAYOUT_TYPES.HORIZONTAL | LAYOUT_TYPES.VERTICAL;
  layoutSemiDarkType: LAYOUT_SEMI_DARK.LIGHT | LAYOUT_SEMI_DARK.DARK;
  layoutSkintype: LAYOUT_SKIN.DEFAULT | LAYOUT_SKIN.BORDERED;
  layoutModeType: LAYOUT_MODE_TYPES.LIGHTMODE | LAYOUT_MODE_TYPES.DARKMODE;
  layoutDirectionType: LAYOUT_DIRECTION.LTR | LAYOUT_DIRECTION.RTL;
  layoutContentWidthType:
    | LAYOUT_CONTENT_WIDTH.FLUID
    | LAYOUT_CONTENT_WIDTH.BOXED;
  layoutSidebarSizeType:
    | LEFT_SIDEBAR_SIZE_TYPES.DEFAULT
    | LEFT_SIDEBAR_SIZE_TYPES.COMPACT
    | LEFT_SIDEBAR_SIZE_TYPES.SMALLICON;
  layoutNavigationType:
    | LEFT_NAVIGATION_TYPES.STICKY
    | LEFT_NAVIGATION_TYPES.SCROLL
    | LEFT_NAVIGATION_TYPES.BORDERED
    | LEFT_NAVIGATION_TYPES.HIDDEN;
  layoutSidebarColorType:
    | LEFT_SIDEBAR_COLOR_TYPES.LIGHT
    | LEFT_SIDEBAR_COLOR_TYPES.DARK
    | LEFT_SIDEBAR_COLOR_TYPES.BRAND
    | LEFT_SIDEBAR_COLOR_TYPES.MODERN;
  layoutTopbarColorType:
    | LAYOUT_TOPBAR_THEME_TYPES.LIGHT
    | LAYOUT_TOPBAR_THEME_TYPES.DARK
    | LAYOUT_TOPBAR_THEME_TYPES.BRAND;
}

export const initialState: LayoutState = {
  layoutType: LAYOUT_TYPES.VERTICAL,
  layoutSemiDarkType: LAYOUT_SEMI_DARK.LIGHT,
  layoutSkintype: LAYOUT_SKIN.DEFAULT,
  layoutModeType: LAYOUT_MODE_TYPES.LIGHTMODE,
  layoutDirectionType: LAYOUT_DIRECTION.LTR,
  layoutContentWidthType: LAYOUT_CONTENT_WIDTH.FLUID,
  layoutSidebarSizeType: LEFT_SIDEBAR_SIZE_TYPES.DEFAULT,
  layoutNavigationType: LEFT_NAVIGATION_TYPES.STICKY,
  layoutSidebarColorType: LEFT_SIDEBAR_COLOR_TYPES.LIGHT,
  layoutTopbarColorType: LAYOUT_TOPBAR_THEME_TYPES.LIGHT,
};

// Create Async Thunks
export const changeLayout = createAsyncThunk(
  "layout/changeLayout",
  async (layout: any) => {
    changeHTMLAttribute("data-layout", layout);
    return layout;
  }
);

export const changeLayoutSemiDark = createAsyncThunk(
  "layout/changeLayoutSemiDark",
  async (sidebarTheme: any) => {
    changeHTMLAttribute("data-topbar", sidebarTheme);
    return sidebarTheme;
  }
);

export const changeSkin = createAsyncThunk(
  "layout/changeSkin",
  async (skinTheme: any) => {
    changeHTMLAttribute("data-skin", skinTheme);
    return skinTheme;
  }
);

export const changeLayoutMode = createAsyncThunk(
  "layout/changeLayoutMode",
  async (layoutMode: any) => {
    changeHTMLAttribute("data-mode", layoutMode);
    return layoutMode;
  }
);

export const changeDirection = createAsyncThunk(
  "layout/changeDirection",
  async (direction: any) => {
    changeHTMLAttribute("dir", direction);
    return direction;
  }
);

export const changeLayoutContentWidth = createAsyncThunk(
  "layout/changeLayoutContentWidth",
  async (contentWidth: any) => {
    changeHTMLAttribute("data-content", contentWidth);
    return contentWidth;
  }
);

export const changeLeftSidebarSizeType = createAsyncThunk(
  "layout/changeLeftSidebarSizeType",
  async (leftSidebarSizeType: any) => {
    switch (leftSidebarSizeType) {
      case "lg":
        changeHTMLAttribute("data-sidebar-size", "lg");
        break;
      case "md":
        changeHTMLAttribute("data-sidebar-size", "md");
        break;
      case "sm":
        changeHTMLAttribute("data-sidebar-size", "sm");
        break;
      default:
        changeHTMLAttribute("data-sidebar-size", "lg");
    }
    return leftSidebarSizeType;
  }
);

export const changeNavigation = createAsyncThunk(
  "layout/changeNavigation",
  async (navigation: any) => {
    changeHTMLAttribute("data-navbar", navigation);
    return navigation;
  }
);

export const changeLeftSidebarColorType = createAsyncThunk(
  "layout/changeLeftSidebarColorType",
  async (sidebarColor: any) => {
    changeHTMLAttribute("data-sidebar", sidebarColor);
    return sidebarColor;
  }
);

export const changeLayoutTopbarColor = createAsyncThunk(
  "layout/changeLayoutTopbarColor",
  async (sidebarColor: any) => {
    changeHTMLAttribute("data-topbar", sidebarColor);
    return sidebarColor;
  }
);

// Layout Slice
const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {
    // Regular synchronous reducers
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeLayout.fulfilled, (state, action) => {
        state.layoutType = action.payload;
      })
      .addCase(changeLayoutSemiDark.fulfilled, (state, action) => {
        state.layoutSemiDarkType = action.payload;
      })
      .addCase(changeSkin.fulfilled, (state, action) => {
        state.layoutSkintype = action.payload;
      })
      .addCase(changeLayoutMode.fulfilled, (state, action) => {
        state.layoutModeType = action.payload;
      })
      .addCase(changeDirection.fulfilled, (state, action) => {
        state.layoutDirectionType = action.payload;
      })
      .addCase(changeLayoutContentWidth.fulfilled, (state, action) => {
        state.layoutContentWidthType = action.payload;
      })
      .addCase(changeLeftSidebarSizeType.fulfilled, (state, action) => {
        state.layoutSidebarSizeType = action.payload;
      })
      .addCase(changeNavigation.fulfilled, (state, action) => {
        state.layoutNavigationType = action.payload;
      })
      .addCase(changeLeftSidebarColorType.fulfilled, (state, action) => {
        state.layoutSidebarColorType = action.payload;
      })
      .addCase(changeLayoutTopbarColor.fulfilled, (state, action) => {
        state.layoutTopbarColorType = action.payload;
      });
  },
});

type DispatchFunction = () => AppDispatch;

export const useLayoutDispatch: DispatchFunction = useDispatch;
export const useLayoutSelector: TypedUseSelectorHook<RootState> = useSelector;

export const createLayoutSelector = (...properties: (keyof LayoutState)[]) => {
  return createSelector(
    (state: RootState) => state.layout,
    (layout) => {
      return properties.reduce((result, prop) => {
        // if (!layout[prop]) {
        // Add a type guard to check if the property exists and is not undefined
        // @ts-ignore
        result[prop] = layout[prop];
        // }
        return result;
      }, {} as Partial<LayoutState>);
    }
  );
};
export const getLayout = (state: RootState) => state.layout;

// Export actions and reducer
export default LayoutSlice.reducer;
