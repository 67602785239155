import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// Define the shape of your student data
export interface StudentInfo {
  // studentEmail: string;
  // studentName?: string;
  studentUserId: string | number;
  citizenship?: string | number | null;
  specialization?: string | null;
  gender?: string | null;
  status?: "idle" | "loading" | "error"; // Individual loading state
  error?: string | null; // Individual error state,
  accessLevel?: number | null;
}

// Define the shape of your initial state
export interface StudentsState {
  [id: string]: StudentInfo;
}

// Initial state (empty by default)
const initialState: StudentsState = {};

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    loadStudent(state, action: PayloadAction<StudentInfo>) {
      const student = action.payload;
      // Store student data by their ID in the state
      state[student.studentUserId] = student;
    },

    reloadStudent(state, action: PayloadAction<Partial<StudentInfo>>) {
      state[action.payload.studentUserId as string] = {
        ...state[action.payload.studentUserId as string],
        ...action.payload,
      };
    },
  },
});

// Function to save the `students` state to localStorage
export const saveStudentsStateToLocalStorage = (students: StudentsState) => {
  try {
    const serializedState = JSON.stringify(students); // Serialize the students object
    localStorage.setItem("CollegePass_Students", serializedState); // Save to localStorage
  } catch (error) {
    console.error("Could not save state to localStorage:", error);
  }
};

// Function to load the `students` state from localStorage
export const loadStudentsStateFromLocalStorage = ():
  | StudentsState
  | undefined => {
  try {
    const serializedState = localStorage.getItem("CollegePass_Students");
    if (serializedState === null) {
      return undefined; // No data in localStorage
    }
    return JSON.parse(serializedState); // Parse the saved state
  } catch (error) {
    console.error("Could not load state from localStorage:", error);
    return undefined;
  }
};

type DispatchFunction = () => AppDispatch;

export const useStudentsDispatch: DispatchFunction = useDispatch;
export const useStudentsSelector: TypedUseSelectorHook<RootState> = useSelector;

// Selector to get student data by studentUserId
export const selectStudentById = (
  state: RootState,
  studentUserId: string | number
) => {
  return state.students[studentUserId];
};

export const { loadStudent, reloadStudent } = studentsSlice.actions;

export default studentsSlice.reducer;
